import { useState } from 'react'
import drawingPlaceholder from '~/assets/images/drawings-placeholder.png'
import textPlaceholder from '~/assets/images/text-placeholder.jpg'

interface ThumbnailProps {
  src?: string | null
  alt?: string | null
  placeholderText?: string | null
  type: string
}

const ProjectThumbnail = ({ src, alt, placeholderText, type }: ThumbnailProps) => {
  const [srcError, setSrcError] = useState<boolean>(false)
  const imagePlaceholder = type === 'drawing' ? drawingPlaceholder : textPlaceholder
  return (
    <figure className="relative w-full h-full grid place-content-center overflow-hidden">
      <div
        className={`rounded-lg overflow-hidden w-full ${!src ? 'blur-[2px] h-full' : ''}`}
      >
        <img
          src={src ?? imagePlaceholder}
          alt={alt ?? 'placeholder image'}
          className="w-full h-full"
          onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const target = event.target as HTMLImageElement
            target.classList.add('blur-[2px]', 'w-32', 'h-full')
            target.onerror = null
            target.src = imagePlaceholder
            setSrcError(true)
          }}
        />
      </div>
      {(!src || srcError) && (
        <p className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-xs min-w-20 text-disabled text-center">
          {placeholderText}
        </p>
      )}
    </figure>
  )
}

export default ProjectThumbnail
