import { PropsWithChildren } from 'react'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '~/components/ui/AlertDialog'
import Checkbox from '../ui/Checkbox'
import Label from '../ui/Label'

import tipsAnimation from '~/assets/images/tips-for-better-results.gif'

interface TipsForBetterResultsDialogProps extends PropsWithChildren {
  onClose: () => void
}

export function TipsForBetterResultsDialog({
  children,
  onClose
}: TipsForBetterResultsDialogProps) {
  const onCheckedChange = (checked: boolean) => {
    window.localStorage.setItem('skip_review_tips', checked.toString())
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent className="min-w-[744px] max-w-[60%]">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-2xl">
            Tips For Better Results
          </AlertDialogTitle>
          <div className="flex w-full">
            <div className="w-[60%]">
              <img src={tipsAnimation} />
            </div>
            <div className="w-[40%] px-6 flex flex-col gap-2">
              <div>
                <h3 className="text-xl font-semibold text-primary">Quick tip</h3>
                <h4 className="text-base font-semibold">
                  Vector Text Guarantees Best Results
                </h4>
              </div>
              <div className="grid gap-2">
                <p className="text-sm">
                  Check your documents for vector text, if you can highlight text
                  you&apos;re good to go!
                </p>
                <p className="text-sm">
                  Don&apos;t worry, mbue will find all text in your document, but without
                  vector text, the quality of detected text may vary.
                </p>
              </div>
            </div>
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex items-center">
          <div className="flex gap-2">
            <Checkbox
              id="do-not-show-tips-checkbox"
              onCheckedChange={onCheckedChange}
              className="border-content-secondary-hover"
            />
            <Label htmlFor="do-not-show-tips-checkbox" className="font-semibold mt-[2px]">
              Don&apos;t show this again.
            </Label>
          </div>
          <AlertDialogAction onClick={onClose}>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default TipsForBetterResultsDialog
