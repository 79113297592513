import { useEffect, useState } from 'react'
import { LoaderCircle } from 'lucide-react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAuth } from 'hooks/useAuth'
import { registerSchema, emailRegisterSchema, FormValues, EmailFormValues } from './types'
import {
  useRegisterUserMutation,
  RegisterUserResponse
} from 'gql/hooks/useRegisterUserMutation'
import {
  useEmailRegisterMutation,
  EmailRegisterResponse
} from 'gql/hooks/useEmailRegisterMutation'
import { EmailLoginSuccess } from '../Login/EmailLoginSuccess'
import { GoogleLogin, CredentialResponse } from '@react-oauth/google'
import Button from '~/components/ui/Button'
import Input from '~/components/ui/Input'
import Label from '~/components/ui/Label'
import Checkbox from '~/components/ui/Checkbox'
import { toast } from 'sonner'
import logo from 'assets/images/mbue_logo_magenta.png'
import Loading from '~/components/ui/Loading'

const defaultValues = {
  fullName: '',
  email: '',
  password: '',
  emailList: false
}

export default function Register() {
  const [loading, setLoading] = useState<boolean>(true)
  const { getUser, setToken, emailLogin } = useAuth()
  const [params] = useSearchParams()
  const navigate = useNavigate()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(registerSchema)
  })

  const {
    register: emailRegister,
    handleSubmit: emailHandleSubmit,
    formState: { errors: emailErrors },
    getValues: getEmailRegisterValues
  } = useForm<EmailFormValues>({
    defaultValues: {
      email: ''
    },
    resolver: zodResolver(emailRegisterSchema)
  })

  const isPasswordEnabled = params.get('password') === 'true'
  const isEmailRegisterSuccess =
    getEmailRegisterValues('email') && params.get('success') === 'true'

  const { registerUser, loading: registerUserLoading } = useRegisterUserMutation({
    onCompleted(data: RegisterUserResponse) {
      if (data.register?.data?.token) {
        setToken(data.register.data.token)
        window.location.reload()
      }
      if (data.register?.error) {
        toast.error(data.register.error, {
          description: 'Please try with another email'
        })
      }
    }
  })

  const { emailRegister: emailRegisterMutation, loading: registerEmailLoading } =
    useEmailRegisterMutation({
      onCompleted(data: EmailRegisterResponse) {
        if (data.emailRegister?.success && data.emailRegister.data?.token) {
          setToken(data.emailRegister.data.token)
          window.location.reload()
        }

        if (data.emailRegister?.success) {
          navigate('/register?success=true')
        }

        if (data.emailRegister?.error) {
          toast.error(data.emailRegister.error, {
            description: 'Please try with another email'
          })
        }
      }
    })

  const onUserRegisterSubmit = (data: FormValues) => {
    const user = {
      email: data.email,
      password: data.password,
      firstName: data.fullName.split(' ').at(0)!,
      lastName: data.fullName.split(' ').slice(1).join(' '),
      emailList: data.emailList,
      inviteToken: window.localStorage.getItem('invite_token') ?? null,
      trial: false
    }

    void registerUser(user)
  }

  const onEmailRegisterSubmit = (data: EmailFormValues) => {
    const emailRegistration = {
      email: data.email,
      trial: false,
      credentials: ''
    }

    void emailRegisterMutation(emailRegistration)
  }

  const onGoogleRegisterSuccess = (credentialResponse: CredentialResponse) => {
    // Avoids to loging again if the user clicks twice on the Google Button
    if (registerEmailLoading) return

    if (credentialResponse.credential) {
      const emailCredentials = {
        email: '',
        credentials: credentialResponse.credential
      }

      void emailLogin(emailCredentials)
    }
  }

  useEffect(() => {
    const runEffect = async () => {
      try {
        const validatedUser = await getUser()
        if (validatedUser?.me?.email) {
          navigate(`/org`)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    void runEffect()
  }, [getUser, navigate])

  const fullNameInput = register('fullName')
  const emailInput = register('email')
  const passwordInput = register('password')

  const emailRegisterInput = emailRegister('email')

  if (loading) return <Loading />

  return (
    <div className="w-full h-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-4 text-center justify-items-center">
            <img className="w-48" src={logo} alt="mbue" />
            <h1 className="text-3xl font-bold">
              {isEmailRegisterSuccess ? 'Check your email' : "Let's get started!"}
            </h1>
            {!isEmailRegisterSuccess && (
              <p className="text-balance text-muted-foreground">Create an Account</p>
            )}
          </div>
          {isPasswordEnabled && !isEmailRegisterSuccess && (
            <form
              className="grid gap-4"
              onSubmit={(event) => void handleSubmit(onUserRegisterSubmit)(event)}
            >
              <div className="grid gap-2">
                <Label htmlFor="fullname">Full Name</Label>
                <Input
                  id="fullname"
                  type="text"
                  {...fullNameInput}
                  onChange={(_, event) => void fullNameInput.onChange(event)}
                  error={errors.fullName?.message}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  {...emailInput}
                  onChange={(_, event) => void emailInput.onChange(event)}
                  error={errors.email?.message}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  {...passwordInput}
                  onChange={(_, event) => void passwordInput.onChange(event)}
                  error={errors.password?.message}
                />
              </div>
              <div className="flex gap-2 items-center">
                <Controller
                  name="emailList"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      id="emailList"
                      ref={field.ref}
                      name={field.name}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                  )}
                />
                <Label htmlFor="emailList" className="font-normal">
                  I agree to join mbue&apos;s email list
                </Label>
              </div>
              <Button type="submit" className="w-full" disabled={registerUserLoading}>
                {registerUserLoading && (
                  <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />
                )}
                Sign up
              </Button>
              <p className="text-xs">
                I agree to{' '}
                <a
                  href="https://docsend.com/view/b6uajeme9jrr4w7t"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary underline underline-offset-2"
                >
                  terms of service
                </a>{' '}
                and{' '}
                <a
                  href="https://docsend.com/view/598zsppf6n9vc5r7"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary underline underline-offset-2"
                >
                  privacy statements
                </a>
              </p>
            </form>
          )}

          {!isPasswordEnabled && !isEmailRegisterSuccess && (
            <form
              className="grid gap-4"
              onSubmit={(event) => void emailHandleSubmit(onEmailRegisterSubmit)(event)}
            >
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  {...emailRegisterInput}
                  onChange={(_, event) => void emailRegisterInput.onChange(event)}
                  placeholder="email@example.com"
                  error={emailErrors.email?.message}
                />
              </div>
              <Button type="submit" className="w-full" disabled={registerEmailLoading}>
                {registerEmailLoading && (
                  <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />
                )}
                Sign Up
              </Button>
              <p className="text-center">or</p>
              <GoogleLogin
                onSuccess={onGoogleRegisterSuccess}
                onError={() => {
                  console.log('>> error')
                }}
                width="350"
                cancel_on_tap_outside
              />
              <p className="text-xs text-center">
                I agree to{' '}
                <a
                  href="https://docsend.com/view/b6uajeme9jrr4w7t"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary underline underline-offset-2"
                >
                  terms of service
                </a>{' '}
                and{' '}
                <a
                  href="https://docsend.com/view/598zsppf6n9vc5r7"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary underline underline-offset-2"
                >
                  privacy statements
                </a>
              </p>
            </form>
          )}

          {isEmailRegisterSuccess && (
            <EmailLoginSuccess
              email={getEmailRegisterValues('email')}
              onResendEmail={(event) =>
                void emailHandleSubmit(onEmailRegisterSubmit)(event)
              }
            />
          )}
          <div className="mt-4 text-center text-sm">
            Already have an account?{' '}
            <Link to={`/login?password=${isPasswordEnabled}`} className="underline">
              Login
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block">
        <img
          src="https://images.squarespace-cdn.com/content/v1/61dca87d8e88f25fc40b0493/2d2a02eb-5b1f-43e7-b74d-ccca82e82897/AdobeStock_601259045.jpeg?format=1500w"
          alt="Image"
          width="1920"
          height="1080"
          className="h-screen w-full object-cover dark:brightness-[0.2] dark:grayscale -scale-x-100 object-[-335px] 2xl:object-[-450px]"
        />
      </div>
    </div>
  )
}
