import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ChevronDownIcon, HomeIcon, LayoutGridIcon, CheckIcon } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuLabel
} from '~/components/ui/DropdownMenu'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator
} from '../ui/Breadcrumb'

import cn from '~/utilities/classNames'

import { useNavigationDataQuery } from '~/graphql/hooks/useNavigationDataQuery'
import ProjectSettingsDialog from '../Projects/ProjectSettingsDialog'

const BreadcrumbNav = () => {
  const params = useParams<{
    orgId: string
    projectId: string
    reviewId: string
  }>()
  const [projectSettingsOpen, setProjectSettingsOpen] = useState<boolean>(false)

  const orgId = parseInt(params.orgId ?? '0')
  const projectId = parseInt(params.projectId ?? '0')
  const reviewId = parseInt(params.reviewId ?? '0')

  const { navigationData: nav, loading } = useNavigationDataQuery()

  const selectedOrg = nav?.orgs[orgId]
  const selectedProject = selectedOrg?.projects[projectId]
  const selectedReview = selectedProject?.jobs[reviewId]

  return (
    <>
      <Breadcrumb
        className={`text-fundation transition-opacity ${loading ? 'opacity-0' : 'opacity-100'}`}
      >
        <BreadcrumbList>
          <BreadcrumbItem className="text-fundation">
            <Link
              to={`/org/${orgId}/projects`}
              className="px-2 py-[6px] rounded-lg hover:bg-content-secondary"
            >
              <HomeIcon size={16} />
            </Link>
          </BreadcrumbItem>

          {orgId && (
            <>
              <BreadcrumbSeparator className="text-fundation" />

              <BreadcrumbItem className="text-fundation">
                <DropdownMenu>
                  <DropdownMenuTrigger className="flex items-center gap-1 text-sm px-2 py-[6px] rounded-lg hover:bg-content-secondary">
                    {selectedOrg?.name}
                    <ChevronDownIcon size={12} />
                  </DropdownMenuTrigger>

                  <DropdownMenuContent
                    align="start"
                    className="bg-content-secondary border-0"
                  >
                    <DropdownMenuItemNav asChild>
                      <Link
                        to={`/org/${orgId}/projects`}
                        className="flex gap-2 items-center"
                      >
                        <LayoutGridIcon size={16} />
                        Go to Projects
                      </Link>
                    </DropdownMenuItemNav>

                    <DropdownMenuSeparator className="bg-disabled" />

                    <DropdownMenuItemNav asChild>
                      <Link to={`org/${orgId}/settings`}>
                        <LayoutGridIcon size={16} className="text-transparent" />
                        Organization Settings...
                      </Link>
                    </DropdownMenuItemNav>

                    <DropdownMenuSeparator className="bg-disabled" />

                    <DropdownMenuLabel className="text-xs font-bold flex gap-2 text-fundation">
                      <CheckIcon size={16} className="text-transparent" />
                      Organizations
                    </DropdownMenuLabel>
                    <div className="max-h-96 overflow-y-auto">
                      {Object.keys(nav?.orgs ?? {}).map((orgKey) => {
                        const org = nav?.orgs[parseInt(orgKey)]
                        return (
                          <DropdownMenuItemNav key={org?.id} asChild>
                            <Link to={`/org/${org?.id}/projects`}>
                              <CheckIcon
                                size={16}
                                className={org?.id === orgId ? '' : 'text-transparent'}
                              />
                              {org?.name}
                            </Link>
                          </DropdownMenuItemNav>
                        )
                      })}
                    </div>
                  </DropdownMenuContent>
                </DropdownMenu>
              </BreadcrumbItem>
            </>
          )}

          {Boolean(projectId) && (
            <>
              <BreadcrumbSeparator className="text-fundation" />

              <BreadcrumbItem className="text-fundation">
                <DropdownMenu>
                  <DropdownMenuTrigger className="flex items-center gap-1 text-sm px-2 py-[6px] rounded-lg hover:bg-content-secondary">
                    {selectedProject?.name}
                    <ChevronDownIcon size={12} />
                  </DropdownMenuTrigger>

                  <DropdownMenuContent
                    align="start"
                    className="bg-content-secondary border-0"
                  >
                    <DropdownMenuItemNav>
                      <LayoutGridIcon size={16} className="text-transparent" />
                      <button onClick={() => setProjectSettingsOpen(true)}>
                        Project Settings...
                      </button>
                    </DropdownMenuItemNav>
                    <DropdownMenuSeparator className="bg-disabled" />
                    <DropdownMenuLabel className="text-xs font-bold flex gap-2 text-fundation">
                      <LayoutGridIcon size={16} className="text-transparent" />
                      Projects
                    </DropdownMenuLabel>
                    <div className="max-h-96 overflow-y-auto">
                      {Object.keys(nav?.orgs[orgId].projects ?? {})
                        .reverse()
                        .map((projectKey) => {
                          const project = nav?.orgs[orgId].projects[parseInt(projectKey)]
                          return (
                            <DropdownMenuItemNav key={project?.id} asChild>
                              <Link to={`/org/${orgId}/project/${project?.id}/reviews`}>
                                <CheckIcon
                                  size={16}
                                  className={
                                    project?.id === projectId ? '' : 'text-transparent'
                                  }
                                />
                                {project?.name}
                              </Link>
                            </DropdownMenuItemNav>
                          )
                        })}
                    </div>
                  </DropdownMenuContent>
                </DropdownMenu>
              </BreadcrumbItem>
            </>
          )}

          {Boolean(reviewId) && (
            <>
              <BreadcrumbSeparator className="text-fundation" />

              <BreadcrumbItem className="text-fundation text-sm px-2 py-[6px] rounded-lg hover:bg-content-secondary">
                {window.location.pathname.match('^.+/\\d+/view$') ? (
                  <a
                    href={`/org/${orgId}/project/${projectId}/reviews/${selectedReview?.id}`}
                  >
                    {selectedReview?.name}
                  </a>
                ) : (
                  <Link
                    to={`/org/${orgId}/project/${projectId}/reviews/${selectedReview?.id}`}
                  >
                    {selectedReview?.name}
                  </Link>
                )}
              </BreadcrumbItem>
            </>
          )}
        </BreadcrumbList>
      </Breadcrumb>
      <ProjectSettingsDialog
        open={projectSettingsOpen}
        setOpen={setProjectSettingsOpen}
        projectName={selectedProject?.name}
      />
    </>
  )
}

export default BreadcrumbNav

function DropdownMenuItemNav({
  children,
  className,
  asChild = false,
  ...props
}: {
  children: React.ReactNode
  className?: string
  asChild?: boolean
}): JSX.Element {
  return (
    <DropdownMenuItem
      className={cn(
        'flex gap-2 text-fundation focus:bg-content-secondary-hover focus:text-fundation pr-6',
        className
      )}
      asChild={asChild}
      {...props}
    >
      {children}
    </DropdownMenuItem>
  )
}
