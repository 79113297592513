import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { ChevronLeft } from 'lucide-react'
import Button from '~/components/ui/Button'
import Input from '~/components/ui/Input'
import Label from '~/components/ui/Label'

interface EmailLoginSuccessProps {
  onResendEmail: (event: FormEvent<HTMLFormElement>) => void
  email: string
}

export const EmailLoginSuccess = ({ email, onResendEmail }: EmailLoginSuccessProps) => {
  const [isSending, setIsSending] = useState(false)

  const handleResend = (event: FormEvent<HTMLFormElement>) => {
    setIsSending(true)
    onResendEmail(event)

    setTimeout(() => setIsSending(false), 2500)
  }

  return (
    <form className="grid gap-4" onSubmit={handleResend}>
      <div className="grid gap-2">
        <Label htmlFor="email">We sent a verification email to</Label>
        <Input
          id="email"
          type="email"
          name="email"
          onChange={() => ({})}
          value={email}
          readOnly
        />
      </div>
      <div className="text-center text-sm">
        Not seeing the email?
        <Button
          className="w-24 justify-start"
          disabled={isSending}
          type="submit"
          variant="link"
        >
          {isSending ? 'Sending...' : 'Resend'}
        </Button>
      </div>
      <div className="mt-8">
        <Link to="/" replace className="flex items-center gap-2 justify-center text-sm underline-offset-4 hover:underline">
          <ChevronLeft size={16} /> Back
        </Link>
      </div>
    </form>
  )
}
