import { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import ReviewCard from './ReviewCard'
import ReviewCardSleketon from './ReviewCardSkeleton'
import { Job } from '~/graphql/hooks/useProjectQuery'

interface ReviewListProps {
  isLoading: boolean
  jobs: Job[]
}

const ReviewsList: FC<ReviewListProps> = ({ isLoading, jobs }) => {
  const { orgId, projectId, reviewId } = useParams<{
    orgId: string
    projectId: string
    reviewId: string
  }>()
  return (
    <ul>
      {!isLoading &&
        jobs.map((job) => {
          return (
            <li key={job.id}>
              <Link to={`/org/${orgId}/project/${projectId}/reviews/${job.id}`}>
                <ReviewCard
                  name={job.name ?? job.revisedDesign.originalFilename}
                  createdAt={job.createdAt}
                  thumbnail={job.downloadUrls.thumbnail}
                  selected={reviewId === job.id.toString()}
                  type={job.jobType}
                />
              </Link>
            </li>
          )
        })}
      {isLoading &&
        new Array(5).fill(null).map((_, index) => <ReviewCardSleketon key={index} />)}
    </ul>
  )
}

export default ReviewsList
