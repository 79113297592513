import { z } from 'zod'
import { AdminJobsFilter } from '~/graphql/hooks/useAdmin'

export const jobFilterSchema = z.object({
  employee: z.boolean().default(true).optional(),
  deleted: z.boolean().default(true).optional(),
  jobId: z.string().default('10'),
  email: z.string().default(''),
  emailSearchType: z.string().default('exact'),
  pageLimit: z.string().default('10'),
  jobType: z.string().default('')
})

export type JobFormValues = z.infer<typeof jobFilterSchema>

export enum JobStatus {
  'SETUP' = 0, //  - setup # job being created
  'REVIEW_STARTING' = 1, //  - review_starting # document differ starting/running
  'COMPLETE' = 2, //  - review_completed # full comparison completed
  'REVIEW_ERROR' = 3, //  - review_error # document differ problem occurred
  'SETUP_ERROR' = 4, //  - setup-error # document failed during setup process (CRITICAL)
  'SMART_MATCHING' = 5, //  - smart-matching # use embeddings etc to auto match pages
  'SMART_MATCHING_ERROR' = 6, //  - smart-matching-error # error during smart marching—can continue to user matching
  'USER_MATCHING' = 7, //  - user-matching # user is reviewing matches
  'QUALITY_CONTROL' = 8, //  - quality_control # staff needs to review for quality control
  'RELEASE' = 9 //  - release # job is ready for releasing to user
}

export enum QualityStatus {
  'UNREVIEWED' = 1,
  'PERFECT' = 2,
  'VERY GOOD' = 3,
  'NEEDS IMPROVEMENT' = 4,
  'FAILURE' = 5,
  'ACCEPTABLE' = 6
}

export enum ReviewStatus {
  'QUEUED' = 1,
  'CLAIMED' = 2,
  'REVIEWED' = 3,
  'CORRECTED' = 4
}

export const defaultFilters: AdminJobsFilter = {
  name: 'job-detail-report',
  page: 1,
  limit: 25,
  filters: {
    employee: false,
    deleted: false,
    jobId: 0,
    email: '',
    emailSearchType: 'exact',
    jobType: ''
  }
}

export const defaultValues = {
  employee: true,
  deleted: true,
  jobId: '',
  email: '',
  emailSearchType: 'exact',
  jobType: 'all',
  pageLimit: '25'
}

export interface JobRowData {
  id: number
  userEmail: string
  employee: string
  orgId: number
  orgName: string
  project: {
    id: number
    name: string
  }
  jobType: string
  status: number
  createdAt: string
  startedAt: string
  deletedAt: string
  completedAt: string
  internalQCReview: {
    reviewStatus: number
    reviewer: string
    qualityStatus: number
    comment: string
    date: string
  }
  revisedDesign: {
    pageCount: number
    originalFilename: string
  }
  comparisonId: string
  diffUri: string
  originalUri: string
  revisedUri: string
  originalDesign: {
    pageCount: number
    originalFilename: string
  }
  quality: string
  downloadUrls: {
    original: string
    revised: string
    thumbnail: string
    preview: string
    overlay: string
    diff: string
    diffOriginal: string
  }
}
