import { gql, QueryHookOptions, useQuery } from '@apollo/client'

const PAGE_SCROLL_MAP_QUERY = gql`
  query GetPageScrollMap($jobId: Int!) {
    getPageScrollMap(jobId: $jobId) {
      data {
        originalLength
        revisedLength
        pageChangeMap
      }
      error
      success
    }
  }
`

export interface ReportChanges {
  data: Record<string, string | number>
}

export interface PageScrollMapResponse {
  getPageScrollMap: {
    data: {
      originalLength: number
      revisedLength: number
      pageChangeMap: [number, number][]
    }
    error: string | null
    success: boolean
  }
}

export const usePageScrollMapQuery = (id: number, options?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useQuery<PageScrollMapResponse>(
    PAGE_SCROLL_MAP_QUERY,
    {
      variables: {
        jobId: id
      },
      ...options
    }
  )

  return {
    pageScrollMap: data?.getPageScrollMap.data,
    loading,
    error,
    refetch
  }
}

export type PageScrollMapQuery = ReturnType<typeof usePageScrollMapQuery>
