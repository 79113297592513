/**
 * Expands a minimal page-scroll map into a full list of (originalPage, revisedPage) pairs
 * that indicate which pages should be displayed together.
 */
export function buildFullPageMap(
  pageScrollData: {
    pageChangeMap: [number, number][]
    originalLength: number
    revisedLength: number
  },
  newestToOriginal = true
): Record<number, number> {
  if (!pageScrollData.pageChangeMap || pageScrollData.pageChangeMap.length === 0) {
    return {}
  }
  const rightSideLength = pageScrollData.revisedLength
  let pageMap = pageScrollData.pageChangeMap
  // Create a map of the revised pages to the original pages
  const result: Record<number, number> = {}
  let currOriginal = 0
  let currRevised = 0
  while (currRevised < rightSideLength) {
    // Find any mapping that matches current indices
    const matchIndex = pageMap.findIndex(
      ([orig, rev]) => orig === currOriginal || rev === currRevised
    )

    if (matchIndex !== -1) {
      const [origPage, revPage] = pageMap[matchIndex]
      // Align both positions to the mapped pages
      currOriginal = origPage
      currRevised = revPage
      // Remove used mapping
      pageMap = pageMap.filter((_, index) => index !== matchIndex)
    }

    // Map current revised page to original
    result[currRevised] = currOriginal

    // Move both positions forward together
    currOriginal++
    currRevised++
  }

  if (!newestToOriginal) {
    // Swap keys and values, keeping first occurrence of each key
    const swapped: Record<number, number> = {}
    Object.entries(result).forEach(([key, value]) => {
      if (!(value in swapped)) {
        swapped[value] = Number(key)
      }
    })
    return Object.fromEntries(
      Object.entries(swapped).map(([key, value]) => [Number(key) + 1, value + 1])
    )
  }
  // Convert the result to be one-indexed
  return Object.fromEntries(
    Object.entries(result).map(([key, value]) => [Number(key) + 1, value + 1])
  )
}
