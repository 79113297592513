import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { pickBy, isEqual, mapValues } from 'lodash'
import { useForm } from 'react-hook-form'
import Button from '~/components/ui/Button'
import Checkbox from '~/components/ui/Checkbox'
import Input from '~/components/ui/Input'
import Label from '~/components/ui/Label'
import {
  Select,
  SelectTrigger,
  SelectGroup,
  SelectItem,
  SelectContent,
  SelectValue
} from '~/components/ui/Select'
import { defaultValues, jobFilterSchema, JobFormValues } from './types'
import { zodResolver } from '@hookform/resolvers/zod'

export interface AdminJobsFilter {
  name: string
  page: number
  limit: number
  filters: {
    employee: boolean
    deleted: boolean
    jobId: string
    email: string
    emailSearchType: string
    jobType: string
  }
}

interface JobsFilterFormParams {
  handleSubmitCallback: (data: JobFormValues) => void
}

export const JobsFilterForm = (props: JobsFilterFormParams) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<JobFormValues>({
    defaultValues: {
      ...defaultValues,
      ...mapValues(Object.fromEntries(searchParams), (val) =>
        val === 'false' ? false : val
      )
    },
    resolver: zodResolver(jobFilterSchema)
  })

  const jobId = register('jobId')
  const email = register('email')
  const employee = register('employee')
  const deleted = register('deleted')
  const formValues = getValues()

  useEffect(() => {
    handleSubmitCallback(formValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitCallback = (data: JobFormValues) => {
    const nonDefaultChanges = pickBy(
      mapValues(data, (val) => (val !== undefined ? val.toString() : null)),
      (v: string, k: string) =>
        !isEqual(defaultValues[k as keyof JobFormValues].toString(), v)
    )
    setSearchParams({ ...nonDefaultChanges })
    props.handleSubmitCallback(data)
  }

  return (
    <>
      <form
        className="bg-slate-300 p-2"
        onSubmit={(event) => void handleSubmit(handleSubmitCallback)(event)}
      >
        <div className="flex justify-center">
          <div className="grid grid-cols-3 gap-4 p-1">
            <div className="">
              <div className="flex gap-2 p-3">
                <Checkbox
                  id="employee"
                  onCheckedChange={(value) => setValue('employee', value ? true : false)}
                  defaultChecked={formValues.employee}
                  {...employee}
                />
                <label
                  htmlFor="employee"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Hide Employees
                </label>
              </div>
              <div className="flex gap-2 p-3 items-center">
                <Checkbox
                  id="deleted"
                  onCheckedChange={(value) => setValue('deleted', value ? true : false)}
                  defaultChecked={formValues.deleted}
                  {...deleted}
                />
                <label
                  htmlFor="deleted"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Hide Deleted
                </label>
              </div>
            </div>
            <div className="">
              <div className="flex gap-2 pb-3 items-center">
                <Label htmlFor="jobId">JobId</Label>
                <Input
                  id="jobId"
                  type="text"
                  {...jobId}
                  onChange={(_, event) => void jobId.onChange(event)}
                  className="w-20"
                />
              </div>
              <div className="flex gap-2 pb-3 items-center">
                <Label className="" htmlFor="email">
                  Email
                </Label>
                <Input
                  id="email"
                  type="text"
                  {...email}
                  onChange={(_, event) => void email.onChange(event)}
                  error={errors.email?.message}
                  className="w-36"
                />
                <Select
                  onValueChange={(value) => setValue('emailSearchType', value)}
                  defaultValue={formValues.emailSearchType}
                >
                  <SelectTrigger className="p-3 w-36">
                    <SelectValue placeholder="" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="partial">partial</SelectItem>
                      <SelectItem value="exact">exact</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="">
              <div className="flex gap-2 pb-3 justify-center items-center">
                <Label htmlFor="pageLimit">Limit</Label>
                <Select
                  onValueChange={(value) => setValue('pageLimit', value)}
                  defaultValue={formValues.pageLimit}
                >
                  <SelectTrigger className="p-3 w-36">
                    <SelectValue placeholder="" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="10">10</SelectItem>
                      <SelectItem value="25">25</SelectItem>
                      <SelectItem value="50">50</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex gap-2 pb-3 justify-center items-center">
                <Label htmlFor="jobType">Type</Label>
                <Select
                  onValueChange={(value) => setValue('jobType', value)}
                  defaultValue={formValues.jobType}
                >
                  <SelectTrigger className="p-3 w-36">
                    <SelectValue placeholder="" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="all">all</SelectItem>
                      <SelectItem value="drawing">drawing</SelectItem>
                      <SelectItem value="text">text</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-2">
          <Button type="submit" className="w-20 bg-slate-500">
            Search
          </Button>
        </div>
      </form>
    </>
  )
}
