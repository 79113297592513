export function getUser(): { user_id: string; email: string } | undefined {
  const token = localStorage.getItem('access_token')
  const user = localStorage.getItem('user_data')

  if (token && token !== 'null' && user) {
    const userData = JSON.parse(user) as { id: number; email: string }
    return {
      user_id: userData.id.toString(),
      email: userData.email
    }
  }
  return undefined
}
