import { useEffect, useState } from 'react'
import { gql, QueryHookOptions, useQuery } from '@apollo/client'

const SMART_REPORT_QUERY = gql`
  query GetSmartReport($jobId: Int!) {
    getSmartReport(jobId: $jobId) {
      data {
        includedColumns
        changes {
          data
        }
      }
      error
      success
    }
  }
`

export interface ReportChanges {
  data: Record<string, string | number>
}

export interface SmartReportQueryResponse {
  getSmartReport: {
    data: {
      includedColumns: string[]
      changes: ReportChanges[]
    }
    error: string | null
    success: boolean
  }
}

export interface SmartReport {
  includedColumns: string[]
  changes: Record<string, string | number>[]
}

export const useSmartReportQuery = (id: number, options?: QueryHookOptions) => {
  const [smartReport, setSmartReport] = useState<SmartReport>({
    includedColumns: [],
    changes: []
  })

  const { data, loading, error, refetch, startPolling, stopPolling } =
    useQuery<SmartReportQueryResponse>(SMART_REPORT_QUERY, {
      variables: {
        jobId: id
      },
      ...options
    })

  useEffect(() => {
    if (!data) {
      setSmartReport({
        includedColumns: [],
        changes: []
      })
    }

    if (data?.getSmartReport?.data?.changes?.length) {
      const changes = data.getSmartReport.data.changes.map((change) => change.data)
      setSmartReport({
        includedColumns: data.getSmartReport.data.includedColumns,
        changes
      })
    }
  }, [data])

  return {
    smartReport,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling
  }
}

export type SmartReportQuery = ReturnType<typeof useSmartReportQuery>
