import { useState, FC, useEffect, PropsWithChildren, useRef, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { Job, useProjectQuery } from '~/graphql/hooks/useProjectQuery'
import { useSmartReportQuery } from '~/graphql/hooks/useSmartReportQuery'
import { useDeleteJobMutation } from '~/graphql/hooks/useDeleteJobMutation'
import moment from 'moment'
import { LoaderCircleIcon, PlusIcon, CheckIcon } from 'lucide-react'
import { useVariableValue } from '@devcycle/react-client-sdk'

import ReviewsSettingsMenu from '~/components/Reviews/ReviewsSettingsMenu'
import ReviewSettingsDialog from '~/components/Reviews/ReviewSettingsDialog'
import { MenuSimple as MenuSimpleIcon } from '~/components/ui/icons/MenuSimple'
import { DrawerLayout, DrawerContent, DrawerMainContent } from '~/components/DrawerLayout'
import Button from '~/components/ui/Button'
import ReviewsList from '~/components/Reviews/ReviewsList'
// import DoctypeTabs from '~/components/DoctypeTabs'
import DownloadsMenuButton from '~/components/Reviews/DownloadsMenuButton'
import NewReviewDialog from '~/components/Reviews/NewReviewDialog'
import Badge, { BadgeProps } from '~/components/ui/Badge'
import { reviewTime } from '~/utilities/datetimeFormats'
import Skeleton from '~/components/ui/Skeleton'
import TipsForBetterResultsDialog from '~/components/Reviews/TipsForBetterResultsDialog'
import SmartReporting from '~/components/Reviews/SmartReporting'
import Mixpanel from '~/utilities/mixpanel'
import { useLocalStorage } from '@uidotdev/usehooks'
import scanningBlueprints from '~/assets/images/scanning-blueprints-gray.gif'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '~/components/ui/AlertDialog'
import { toast } from 'sonner'

const POLLING_INTERVAL_5_MINUTES = 5 * 60 * 1000
const ERROR_STATUSES = ['review error', 'setup error', 'smart matching error']
const IN_PROGRESS_STATUSES = [
  'setup',
  'review starting',
  'smart matching',
  'user matching',
  'quality control'
]

const Reviews: FC = () => {
  const asideRef = useRef<HTMLDivElement>(null)
  const [isSmartOverlayEnabled] = useLocalStorage('smart-overlay-enabled', false)
  const isSmartReportEnabled = useVariableValue('smart-reporting-enabled', false)

  const [createReviewOpen, setCreateReviewOpen] = useState<boolean>(false)
  const [asideOpen, setAsideOpen] = useState<boolean>(true)
  const [selectedReview, setSelectedReview] = useState<Job | null>()
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const { orgId, projectId, reviewId } = useParams<{
    orgId: string
    projectId: string
    reviewId: string
  }>()

  const skipReviewTips =
    (window.localStorage.getItem('skip_review_tips') ?? 'false') === 'true'

  const {
    project,
    loading: isProjectLoading,
    startPolling,
    stopPolling
  } = useProjectQuery({
    projectId: parseInt(projectId!)
  })

  const {
    smartReport,
    loading: isReportLoading,
    startPolling: reportPolling,
    stopPolling: reportStopPolling
  } = useSmartReportQuery(parseInt(reviewId!), {
    skip: !reviewId,
    fetchPolicy: 'cache-and-network'
  })

  const { deleteJob, loading: isDeleting } = useDeleteJobMutation()

  const onDeleteconfirm = async () => {
    const response = await deleteJob(parseInt(reviewId!))
    if (response) {
      toast.success(`Review ${reviewId} deleted.`)
      setIsDeleteConfirmOpen(false)
      // For some reason we need the timeout
      // so we can trigger the click from the button ref
      setTimeout(() => {
        asideRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        navigate(`/org/${orgId}/project/${projectId}/reviews`)
      })
    }
  }

  const isPageLoading = isProjectLoading || isReportLoading

  useEffect(() => {
    if (reviewId && project?.jobs) {
      const review = project.jobs.find((job) => job.id.toString() === reviewId)

      setSelectedReview(review)

      const { label } = getReviewStatus(review?.statusDetail ?? '')

      if ('In Progress' === label) {
        startPolling(POLLING_INTERVAL_5_MINUTES)
        reportPolling(POLLING_INTERVAL_5_MINUTES)
      } else {
        stopPolling()
        reportStopPolling()
      }
    } else if (!reviewId && project?.jobs.length) {
      const review = project.jobs[0]
      navigate(`/org/${orgId}/project/${projectId}/reviews/${review.id}`, {
        replace: true
      })
    } else {
      setSelectedReview(null)
    }
  }, [
    navigate,
    orgId,
    project?.jobs,
    projectId,
    reportPolling,
    reportStopPolling,
    reviewId,
    startPolling,
    stopPolling
  ])

  const shouldRenderSmartReport =
    isSmartReportEnabled && Boolean(smartReport.changes.length)

  return (
    <>
      <DrawerLayout open={asideOpen} setOpen={setAsideOpen} hideCloseButton={!reviewId}>
        <DrawerContent className="h-full flex flex-col w-[368px]">
          <div className="h-[72px] flex justify-between items-center px-6 border-b border-divider">
            <h4 className="text-xl font-semibold">Reviews</h4>
            <div className="flex gap-4">
              {skipReviewTips ? (
                <Button className="flex gap-2" onClick={() => setCreateReviewOpen(true)}>
                  <PlusIcon size={16} />
                  <span>New</span>
                </Button>
              ) : (
                <TipsForBetterResultsDialog onClose={() => setCreateReviewOpen(true)}>
                  <Button className="flex gap-2">
                    <PlusIcon size={16} />
                    <span>New</span>
                  </Button>
                </TipsForBetterResultsDialog>
              )}
            </div>
          </div>
          <div ref={asideRef} className="overflow-y-auto flex-1">
            <ReviewsList isLoading={isProjectLoading} jobs={project?.jobs ?? []} />
          </div>
        </DrawerContent>

        <DrawerMainContent className="w-full h-[calc(100vh_-_56px)]">
          {isPageLoading && <LoadingState />}

          {!isPageLoading && (project?.jobs ?? []).length === 0 && (
            <EmptyState open={createReviewOpen} setOpen={setCreateReviewOpen} />
          )}

          {!isPageLoading && selectedReview && (
            <div className="pr-6 pl-4 flex flex-col h-full pb-4">
              <div className="h-[72px] flex justify-between items-center border-b border-divider">
                <div className="flex gap-2 items-center">
                  <button
                    onClick={() => setAsideOpen((prevState) => !prevState)}
                    className={`border border-divider rounded-full p-[3px] ${asideOpen && 'hidden'}`}
                  >
                    <MenuSimpleIcon />
                  </button>
                  <h2 className="text-xl text-content font-semibold">
                    {selectedReview?.name ??
                      selectedReview?.revisedDesign.originalFilename}
                  </h2>
                  <ReviewsSettingsMenu
                    onClickEdit={() => setIsEditModalOpen(true)}
                    onClickDelete={() => setIsDeleteConfirmOpen(true)}
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <a
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 h-10 px-4 py-2 bg-primary text-primary-foreground hover:bg-primary/90"
                    href={`/org/${orgId}/project/${projectId}/reviews/${reviewId}/view`}
                    onClick={(event) => {
                      if (selectedReview.status !== 2) {
                        event.preventDefault()
                        return
                      }
                      Mixpanel.track({
                        event: 'FE:view-results',
                        data: {
                          mode: 'webviewer',
                          type: 'diff'
                        }
                      })
                    }}
                    aria-disabled={selectedReview.status !== 2}
                  >
                    View Results
                  </a>
                  <DownloadsMenuButton
                    originalUrl={selectedReview.downloadUrls.original}
                    revisedUrl={selectedReview.downloadUrls.revised}
                    diffUrl={
                      isSmartOverlayEnabled && selectedReview.jobType === 'drawing'
                        ? selectedReview.downloadUrls.overlay
                        : selectedReview.downloadUrls.diff
                    }
                    smartReportUrl={selectedReview.downloadUrls.reportCsv}
                  />
                </div>
              </div>
              <div className="flex justify-between items-center py-2">
                <div className="grid">
                  <div>
                    <Badge
                      {...(getReviewStatus(
                        selectedReview.statusDetail ?? ''
                      ) as BadgeProps)}
                    />
                  </div>
                  <p className="text-sm text-disabled">
                    {isSmartReportEnabled && Boolean(smartReport.changes.length) ? (
                      <span>{smartReport.changes.length} Changes Found</span>
                    ) : (
                      <span>
                        {selectedReview.completedAt ? 'Completed ' : 'Created '}
                        {moment(
                          selectedReview.completedAt ??
                            selectedReview.startedAt ??
                            selectedReview.createdAt
                        ).fromNow()}
                      </span>
                    )}
                  </p>
                  <p className="text-sm text-disabled">
                    Review Time:{' '}
                    {reviewTime(
                      selectedReview.startedAt ?? selectedReview.createdAt,
                      selectedReview.completedAt ?? ''
                    )}
                    {shouldRenderSmartReport && (
                      <span>
                        {' | '}
                        {selectedReview.completedAt ? 'Completed ' : 'Created '}
                        {moment(
                          selectedReview.completedAt ??
                            selectedReview.startedAt ??
                            selectedReview.createdAt
                        ).fromNow()}
                      </span>
                    )}
                  </p>
                </div>
              </div>
              {selectedReview.statusDetail === 'quality control' ? (
                renderProgressState()
              ) : shouldRenderSmartReport ? (
                <SmartReporting
                  data={smartReport}
                  reportType={selectedReview.jobType as 'drawing' | 'text'}
                />
              ) : (
                renderProgressState()
              )}
            </div>
          )}
        </DrawerMainContent>
      </DrawerLayout>

      <NewReviewDialog open={createReviewOpen} setOpen={setCreateReviewOpen} />

      <ReviewSettingsDialog
        reviewName={selectedReview?.name ?? ''}
        reviewId={selectedReview?.id ?? 0}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
      />

      <ConfirmDeleteDialog
        open={isDeleteConfirmOpen}
        setOpen={setIsDeleteConfirmOpen}
        onConfirm={onDeleteconfirm}
        loading={isDeleting}
      />
    </>
  )

  function renderProgressState() {
    return (
      <div
        className="flex-grow flex justify-center items-center bg-muted bg-cover bg-center border border-divider rounded-md"
        style={{
          backgroundImage:
            selectedReview!.statusDetail === 'Review Completed'
              ? `url(${selectedReview!.downloadUrls.preview})`
              : ''
        }}
      >
        {getStatusforUI(selectedReview!.statusDetail) !== 'finished' && (
          <div className="w-1/2 flex flex-col gap-4">
            {getStatusforUI(selectedReview!.statusDetail) === 'progress' && (
              <>
                <h3 className="text-center font-semibold">Your Review is in Progress!</h3>
                <div className="flex flex-col items-center gap-4">
                  <div className="flex flex-col justify-center text-sm">
                    {getStepsStatus(selectedReview).map((step) => (
                      <div
                        key={step.step}
                        className="flex gap-2 items-center justify-center"
                      >
                        <p>{step.label}</p>
                        {step.icon}
                        {step.icon && step.message && <p>({step.message})</p>}
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col justify-center">
                    <img
                      className="mx-auto grayscale-50"
                      src={scanningBlueprints}
                      width={160}
                    />
                  </div>
                </div>
              </>
            )}

            {getStatusforUI(selectedReview!.statusDetail) === 'error' && (
              <>
                <h3 className="text-center font-semibold text-error">Uh Oh!</h3>

                <div className="text-center text-sm text-error">
                  We encountered a problem with your review, You didn&apos;t do anything
                  wrong, but it might take a bit longer for us to get this review
                  completed. It should complete withing an hour, but if it takes longer,
                  someone from MBUE will contact you.
                </div>
              </>
            )}

            <p className="text-center text-sm">
              You can leave this page and your review will continue to process. Come back
              any time to check the status. Your will receive an email when it is complete
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default Reviews

type ConfirmDialogProps = PropsWithChildren<{
  open?: boolean
  setOpen?: (open: boolean) => void
  onConfirm: () => Promise<void>
  loading?: boolean
}>

export function ConfirmDeleteDialog({
  children,
  onConfirm,
  open,
  setOpen,
  loading
}: ConfirmDialogProps) {
  const { reviewId } = useParams<{
    reviewId: string
  }>()
  return (
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirm Deletion</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to delete the review {reviewId}? <br />
            this action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setOpen?.(false)}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => void onConfirm()} disabled={loading}>
            {loading && <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />}
            Yes, Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

function LoadingState() {
  return (
    <div className="px-4 flex flex-col h-full pb-4">
      <div className="border-b border-b-divider h-[72px] py-1">
        <Skeleton className="w-full h-full flex items-center justify-between px-2">
          <Skeleton className="w-1/3 h-9" />
          <Skeleton className="w-1/4 h-9" />
        </Skeleton>
      </div>
      <div className="flex-grow flex flex-col">
        <div className="py-1">
          <Skeleton className="w-full h-[72px] flex flex-col gap-[2px] justify-center px-4">
            <Skeleton className="w-[64px] h-4" />
            <Skeleton className="w-1/4 h-4" />
            <Skeleton className="w-1/4 h-4" />
          </Skeleton>
        </div>
        <div className="py-2 flex-grow">
          <Skeleton className="w-full h-full grid place-content-center">
            <Skeleton className="h-10 w-32" />
          </Skeleton>
        </div>
      </div>
    </div>
  )
}

function EmptyState({ setOpen }: { open?: boolean; setOpen: (open: boolean) => void }) {
  const skipReviewTips =
    (window.localStorage.getItem('skip_review_tips') ?? 'false') === 'true'
  return (
    <div className="w-full h-[calc(100vh_-_56px)] grid place-content-center">
      <div className="grid gap-2">
        <p>Select a Review to view it, or</p>
        {skipReviewTips ? (
          <Button className="flex gap-2" onClick={() => setOpen(true)}>
            <PlusIcon size={16} />
            <span>New</span>
          </Button>
        ) : (
          <TipsForBetterResultsDialog onClose={() => setOpen(true)}>
            <Button className="flex gap-1">
              <PlusIcon size={16} />
              <span>Start a new Review</span>
            </Button>
          </TipsForBetterResultsDialog>
        )}
      </div>
    </div>
  )
}

function getReviewStatus(status: string): { label: string; variant: string } {
  if (!status) return { label: 'unknown', variant: 'neutral' }

  // Do not display the error state to the user. We will fix the issue for them and rerun the job.
  if ([...IN_PROGRESS_STATUSES, ...ERROR_STATUSES].includes(status.toLowerCase()))
    return { label: 'In Progress', variant: 'warning' }

  return { label: 'Completed', variant: 'success' }
}

function getStatusforUI(status: string | undefined): 'progress' | 'finished' | 'error' {
  if (!status) return 'finished'

  if ([...IN_PROGRESS_STATUSES, ...ERROR_STATUSES].includes(status.toLowerCase()))
    return 'progress'

  // if(['review complete'].includes(status.toLowerCase()))
  return 'finished'
}

interface StepStatus {
  step: string
  label: string
  icon: ReactNode
  message: string
}

function getStepsStatus(review: Job | null | undefined): StepStatus[] {
  if (!review) return []

  const status = review.statusDetail ?? 'setup'
  const totalPages = review.matchedPages
  const completedPages = review.completedPages

  const analyzingStatuses = ['setup', 'review starting']
  const matchingStatuses = ['smart matching']
  const comparingStatuses = ['user matching']
  const finalizingStatuses = ['user matching']

  const isAnalyzing = analyzingStatuses.includes(status)
  const isMatching = matchingStatuses.includes(status)
  const isComparing = comparingStatuses.includes(status) && completedPages < totalPages
  const hasError = ERROR_STATUSES.includes(status.toLowerCase())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isFinalizing =
    finalizingStatuses.includes(status) && completedPages === totalPages

  const getIcons = () => {
    if (isAnalyzing)
      return [
        <LoaderCircleIcon key="analyzingDocuments" size={14} className="animate-spin" />,
        null,
        null,
        null
      ]

    if (isMatching)
      return [
        <CheckIcon key="analyzingDocuments" size={14} />,
        <LoaderCircleIcon key="matchingPages" size={14} className="animate-spin" />,
        null,
        null
      ]

    if (isComparing)
      return [
        <CheckIcon key="analyzingDocuments" size={14} />,
        <CheckIcon key="matchingPages" size={14} />,
        <LoaderCircleIcon key="comparingPages" size={14} className="animate-spin" />,
        null
      ]

    return [
      <CheckIcon key="analyzingDocuments" size={14} />,
      <CheckIcon key="matchingPages" size={14} />,
      <CheckIcon key="comparingPages" size={14} />,
      <LoaderCircleIcon key="finalizingOutput" size={14} className="animate-spin" />
    ]
  }

  return [
    {
      step: 'analyzingDocuments',
      label: 'Analyzing Documents...',
      icon: getIcons()[0],
      message: ''
    },
    {
      step: 'matchingPages',
      label: 'Matching Pages...',
      icon: getIcons()[1],
      message: ''
    },
    {
      step: 'comparingPages',
      label: 'Comparing Pages...',
      icon: getIcons()[2],
      message: hasError
        ? ''
        : `${totalPages - completedPages} out of ${totalPages} completed`
    },
    {
      step: 'finalizingOutput',
      label: 'Finalizing Output...',
      icon: getIcons()[4],
      message: ''
    }
  ]
}
