import { LoaderCircle } from 'lucide-react'
import { cva, type VariantProps } from 'class-variance-authority'

export const loadingVariants = cva(
  'fixed w-full h-screen z-40 top-0 grid place-items-center',
  {
    variants: {
      mode: {
        light: 'bg-white',
        dark: 'bg-[#333333]'
      }
    },
    defaultVariants: {
      mode: 'light'
    }
  }
)

export const spinnerVariants = cva('h-8 w-8 opacity-60 animate-spin', {
  variants: {
    mode: {
      light: 'text-content',
      dark: 'text-[#e64486]'
    }
  },
  defaultVariants: {
    mode: 'light'
  }
})

export interface LoadingProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof loadingVariants> {}

export default function Loading({ mode }: LoadingProps) {
  return (
    <div className={loadingVariants({ mode })}>
      <LoaderCircle className={spinnerVariants({ mode })} />
    </div>
  )
}
